import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "next-i18next";
import Form from "@common/components/form";
import Listbox from "@common/components/form/Listbox";
import useCreateLeadMutation from "@modules/leads/hooks/useCreateLeadMutation";
import Button from "@common/components/Button";
import ArrowRightIcon from "@common/components/icons/ArrowRightIcon";
import Feedback from "@common/components/form/Feedback";
import LoadingSpinner from "@common/components/LoadingSpinner";
import { usePhoneNumberMask } from "@common/hooks/useMask";
import customerValidation from "@common/helpers/validations/customerValidation";
import useGetLocations from "@modules/locations/hooks/useGetLocations";
import useWindowDimensions from "@common/hooks/useWindowDimensions";
import cloudflareImagesLoader from "@common/helpers/imageLoaders/cloudflareImagesLoader";
import classNames from "classnames";
import TextLink from "@common/components/TextLink";
import determineLeadDealer from "@common/helpers/determineLeadDealer";
import useUser from "@modules/user/hooks/useUser";
import {
  BaseVehicleDetail,
  VehicleDetail,
} from "@modules/vehicle/types/VehicleDetail";
import {
  convertDealerLocationToMapLocation,
  findClosestLocation,
} from "@common/helpers/distanceMatrixHelper";
import PhoneInput from "@common/components/form/PhoneInput";
import useCreateGravityFormEntryMutation from "../hooks/useCreateGravityFormEntryMutation";
import FormSubmissionModal from "./FormSubmissionModal";
import FormContentHeader from "./FormContentHeader";
import {
  CheckboxFieldLayout,
  ConsentFieldLayout,
  DropdownFieldLayout,
  EmailFieldLayout,
  GravityFormInput,
  GravityFormLayout,
  NameFieldLayout,
  PhoneFieldLayout,
  SectionBreakFieldLayout,
  SingleLineInputFieldLayout,
  TextAreaFieldLayout,
} from "../types/GravityFormLayout";
import { BackgroundImageGroup } from "../types/BackgroundImageGroup";
import { CmsBackgroundType } from "../types/CmsBackground";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";
import { sizeClass } from "../types/SizeClass";
import { DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH } from "../constants/BackgroundImages";
import FormSelectorBackgroundImage from "./FormSelectorBackgroundImage";
import {
  getCheckboxNames,
  getDropdownName,
  getEmailFieldName,
  getNameFieldName,
  getPhoneFieldName,
  sanitizeFieldName,
} from "../helpers/gravityFormsFieldName";

type Props = {
  form: GravityFormLayout["form"];
  formId: number;
  vinsolutionsId?: number;
  campaignTag: string;
  backgroundImageGroup?: BackgroundImageGroup;
  background?: CmsBackgroundType;
  confirmationText?: string;
  submitButtonText?: string;
  isPopup?: boolean;
  onSubmit?: (formInput: GravityFormInput) => void;
  onError?: () => void;
  vehicle?: VehicleDetail | BaseVehicleDetail;
};

const GravityForm = ({
  form,
  formId,
  vinsolutionsId,
  campaignTag,
  backgroundImageGroup,
  background,
  confirmationText,
  submitButtonText,
  isPopup = false,
  onSubmit,
  onError,
  vehicle,
}: Props) => {
  const createLeadMutation = useCreateLeadMutation();
  const createGravityFormEntryMutation = useCreateGravityFormEntryMutation();
  const { locations } = useGetLocations();
  const { user } = useUser();
  const { t } = useTranslation(["common"]);
  const { unMaskPipe: phoneNumberUnMaskPipe } = usePhoneNumberMask();
  const [width, setWidth] = useState(0);
  const { width: w } = useWindowDimensions();
  useEffect(() => {
    setWidth(w);
  }, [w]);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);
  const [selectedDropdownValues, setSelectedDropdownValues] = useState<
    { name: string; value: { name: string; id: string } }[]
  >([]);
  const [selectedCheckboxValues, setSelectedCheckboxValues] = useState<
    {
      [key: string]: boolean;
    }[]
  >([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitted },
    setValue,
    getValues,
  } = useForm<GravityFormInput>({
    mode: "onSubmit",
  });

  const setClosestLocationToCustomer = useCallback(() => {
    if (user && user.longitude && user.latitude) {
      const location = findClosestLocation(
        user.latitude,
        user.longitude,
        locations.map((l) => convertDealerLocationToMapLocation(l))
      );
      if (location) {
        setSelectedDropdownValues((prevState) => [
          ...prevState,
          {
            name: "preferredLocation",
            value: { name: location.name, id: location.id },
          },
        ]);
        setValue("preferredLocation", { name: location.name, id: location.id });
      }
    }
  }, [locations, setValue, user]);

  useEffect(() => {
    if (
      form.formFields.nodes.find(
        (field) =>
          field.type === "SELECT" &&
          getDropdownName((field as DropdownFieldLayout).label) ===
            "preferredLocation"
      ) &&
      getValues().preferredLocation === undefined &&
      !selectedDropdownValues.find((item) => item.name === "preferredLocation")
    ) {
      setClosestLocationToCustomer();
    }
  }, [
    getValues,
    selectedDropdownValues,
    setClosestLocationToCustomer,
    form.formFields.nodes,
  ]);

  const handleDropdownChange = (
    name: string,
    value: { name: string; id: string }
  ) => {
    setSelectedDropdownValues((prevState) => {
      // Check if an item with the same name already exists in the array
      const index = prevState.findIndex((item) => item.name === name);
      if (index !== -1) {
        // Update the existing item
        return [
          ...prevState.slice(0, index),
          { name, value },
          ...prevState.slice(index + 1),
        ];
      }
      // Add a new item to the array
      return [...prevState, { name, value }];
    });
  };

  const handleCheckboxChange = (
    name: string,
    value: boolean,
    inputName: string
  ) => {
    setSelectedCheckboxValues((prevState) => {
      if (value) {
        // Remove any existing entry with the same input name
        const filteredState = prevState.filter(
          (checkboxValues) =>
            !Object.keys(checkboxValues).some((key) => {
              const keyParts = key.split("-");
              return keyParts[0] === inputName;
            })
        );

        // Add the new entry
        return [...filteredState, { [name]: true }];
      }
      // If the value is false, remove the entry
      return prevState.filter(
        (checkboxValues) =>
          !Object.prototype.hasOwnProperty.call(checkboxValues, name)
      );
    });
  };

  const validateCheckboxFields = (formInput: GravityFormInput) => {
    let checkedRequiredCheckboxes = 0;
    const requiredCheckboxes = form.formFields.nodes.filter((field) => {
      return (
        field.type === "CHECKBOX" && (field as CheckboxFieldLayout).isRequired
      );
    });
    requiredCheckboxes.forEach((field) => {
      const checkboxNames = getCheckboxNames(field as CheckboxFieldLayout);
      if (checkboxNames.some((input) => formInput[input] === true)) {
        checkedRequiredCheckboxes += 1;
      }
    });
    return checkedRequiredCheckboxes === requiredCheckboxes.length;
  };

  const handleFormSubmit = async (formInput: GravityFormInput) => {
    if (!validateCheckboxFields(formInput)) return;

    let leadMessage = "";
    let preferredLocation;
    let userSelectedDealerId;

    if (formInput?.message && formInput?.referredBy) {
      leadMessage = `Referred by: ${formInput.referredBy}\n`;
    }
    // Filter out all known fields and leave the rest as a message
    Object.entries(formInput)
      .filter(
        ([key]) =>
          key !== "firstName" &&
          key !== "lastName" &&
          key !== "email" &&
          key !== "phoneNumber" &&
          key !== "purpose" &&
          key !== "chosenOffer" &&
          key !== "referredBy" &&
          key !== "friendsFirstName" &&
          key !== "friendsLastName" &&
          key !== "referralEmail" &&
          key !== "referralPhoneNumber" &&
          key !== "preferredLocation" &&
          key !== "consent"
      )
      .forEach(([key, value]) => {
        if (
          typeof value === "object" &&
          Object.prototype.hasOwnProperty.call(value, "name")
        ) {
          leadMessage += `${key}: ${(value as { name: string }).name}\n`;
          return;
        }
        if (key.includes("-")) {
          if (value) leadMessage += `${key} Selected\n`;
          return;
        }
        leadMessage += `${key}: ${value ?? "Not Selected"}\n`;
      });

    if (formInput.preferredLocation) {
      const selectedLocation = locations.find(
        (location) => location.name === formInput.preferredLocation.name
      );
      preferredLocation = selectedLocation;
      userSelectedDealerId = selectedLocation?.id;
    }

    if (preferredLocation && user && vehicle?.location) {
      await determineLeadDealer(
        locations,
        user,
        vehicle.location,
        preferredLocation
      ).then((location) => {
        preferredLocation = location;
      });
    }

    createGravityFormEntryMutation.mutate({
      formId,
      formInput,
      formFields: form.formFields.nodes,
    });

    createLeadMutation.mutate(
      {
        firstName: formInput.firstName,
        lastName: formInput.lastName,
        phoneNumber: formInput.phoneNumber || undefined,
        email: formInput.email,
        friendsFirstname: formInput.friendsFirstName,
        friendsLastname: formInput.friendsLastName,
        referralEmail: formInput.referralEmail,
        referralPhoneNumber: formInput.referralPhoneNumber,
        inquiry: formInput.purpose?.name,
        chosenOffer: formInput.chosenOffer?.name,
        originUrl: document.location.href,
        leadDescription: campaignTag,
        dealerId: preferredLocation?.id ?? vehicle?.location?.id,
        userSelectedDealerId,
        vinSolutionsDealerId: vinsolutionsId,
        message: leadMessage,
        vehicleId: vehicle?.id,
        userDataConsentGiven: true,
        skipVinSolutionsSubmission:
          !vehicle?.location?.id && !preferredLocation?.id && !vinsolutionsId,
      },
      {
        onSuccess: () => {
          reset();
          setSelectedDropdownValues([]);
          setSelectedCheckboxValues([]);
          if (!isPopup) setIsSubmitModalOpen(true);
          if (onSubmit) onSubmit(formInput);
        },
        onError: () => {
          onError?.();
        },
      }
    );
  };
  return (
    <div
      className="overflow-visible no-scrollbar relative bg-cover bg-center"
      style={background && GetBackgroundStyle(background)}
    >
      <LoadingSpinner isLoading={createLeadMutation.isPending} isOverlay />
      {isSubmitModalOpen && (
        <FormSubmissionModal
          isOpen={isSubmitModalOpen}
          setState={setIsSubmitModalOpen}
          modalDescription={confirmationText}
        />
      )}
      <div
        className={classNames({
          "bg-no-repeat": backgroundImageGroup?.image,
          "bg-[center_right_-16rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["2xl"] &&
            width >= sizeClass.xl,
          "bg-[center_right_-8rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["3xl"] &&
            width >= sizeClass["2xl"],
          "bg-[center_right_1rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width < sizeClass["4xl"] &&
            width >= sizeClass["3xl"],
          "bg-[center_right_14rem]":
            backgroundImageGroup?.imagePosition === "Right" &&
            width >= sizeClass["4xl"],

          "bg-[center_left_-14rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["2xl"] &&
            width >= sizeClass.xl,
          "bg-[center_left_-6rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["3xl"] &&
            width >= sizeClass["2xl"],
          "bg-[center_left_2rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width < sizeClass["4xl"] &&
            width >= sizeClass["3xl"],
          "bg-[center_left_16rem]":
            backgroundImageGroup?.imagePosition === "Left" &&
            width >= sizeClass["4xl"],
        })}
        style={
          width >= sizeClass.xl &&
          backgroundImageGroup?.image &&
          backgroundImageGroup.image.length > 0
            ? {
                backgroundImage: `url(${cloudflareImagesLoader({
                  src: backgroundImageGroup?.image[0].sourceUrl || "",
                  width: DEFAULT_FORM_SELECTOR_BACKGROUND_WIDTH,
                })})`,
              }
            : {}
        }
      >
        <div
          className={classNames(
            "relative max-w-page mx-auto lg:mx-0 xl:mx-auto flex items-center",
            {
              "py-10": !isPopup,
              "flex-col": backgroundImageGroup,
              "px-5 items-center lg:items-stretch":
                !backgroundImageGroup ||
                !backgroundImageGroup.image ||
                (backgroundImageGroup.image &&
                  backgroundImageGroup.image.length === 0),
              "lg:flex-row px-5 lg:pl-5 lg:pr-0 xl:px-5":
                backgroundImageGroup?.imagePosition === "Right" &&
                backgroundImageGroup.image &&
                backgroundImageGroup.image.length > 0,
              "lg:flex-row-reverse px-5 lg:pl-0 lg:pr-5 xl:px-5":
                backgroundImageGroup?.imagePosition === "Left" &&
                backgroundImageGroup.image &&
                backgroundImageGroup.image.length > 0,
            }
          )}
        >
          <div
            className={classNames("", {
              "flex-1 lg:basis-[60%] xl:basis-[50%]":
                backgroundImageGroup?.image,
            })}
          >
            {!isPopup && (
              <FormContentHeader
                title={form.title}
                description={form.description}
              />
            )}
            <form
              className="my-5 flex flex-col gap-5 md:grid md:grid-cols-2"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              {form.formFields.nodes.map((field) => {
                switch (field.type) {
                  case "NAME": {
                    const nameField = field as NameFieldLayout;
                    return nameField.inputs.map((input) => {
                      const inputLabel = getNameFieldName(
                        input.label,
                        input.customLabel
                      );
                      if (
                        !input.isHidden &&
                        (input.label === "First" || input.label === "Last")
                      ) {
                        return (
                          <div
                            key={`${nameField.databaseId}-${inputLabel}`}
                            className={nameField.cssClass ?? ""}
                          >
                            <Form.Input
                              id={inputLabel}
                              autoComplete={input.autocompleteAttribute}
                              label={input.customLabel || input.label}
                              placeholder={input.placeholder}
                              maxLength={customerValidation.firstName.maxLength}
                              feedback={errors[inputLabel]?.message}
                              status={errors[inputLabel] ? "error" : undefined}
                              suppressHotjar
                              {...register(inputLabel, {
                                required: {
                                  value: nameField.isRequired,
                                  message:
                                    nameField.errorMessage ||
                                    t("common:required"),
                                },
                              })}
                            />
                          </div>
                        );
                      }
                      return null;
                    });
                  }
                  case "EMAIL": {
                    const emailField = field as EmailFieldLayout;
                    const emailInputLabel = getEmailFieldName(
                      emailField.inputs[0].label,
                      emailField.inputs[0].customLabel
                    );
                    return (
                      <div
                        key={emailField.databaseId}
                        className={emailField.cssClass ?? ""}
                      >
                        <Form.Input
                          id={emailInputLabel}
                          autoComplete={
                            emailField.inputs[0].autocompleteAttribute
                          }
                          label={
                            emailField.inputs[0].customLabel ||
                            emailField.inputs[0].label
                          }
                          placeholder={emailField.inputs[0].placeholder}
                          maxLength={customerValidation.email.maxLength}
                          feedback={errors[emailInputLabel]?.message}
                          status={errors[emailInputLabel] ? "error" : undefined}
                          suppressHotjar
                          {...register(emailInputLabel, {
                            required: {
                              value: emailField.isRequired,
                              message:
                                emailField.errorMessage || t("common:required"),
                            },
                            pattern: {
                              value: customerValidation.email.pattern,
                              message: t("common:invalid_email"),
                            },
                          })}
                        />
                      </div>
                    );
                  }
                  case "PHONE": {
                    const phoneField = field as PhoneFieldLayout;
                    const phoneInputLabel = getPhoneFieldName(phoneField.label);
                    return (
                      <div
                        key={phoneField.databaseId}
                        className={phoneField.cssClass ?? ""}
                      >
                        <PhoneInput
                          id={phoneInputLabel}
                          autoComplete={phoneField.autocompleteAttribute}
                          label={phoneField.label}
                          maxLength={customerValidation.phoneNumber.maxLength}
                          placeholder={phoneField.placeholder}
                          feedback={errors[phoneInputLabel]?.message}
                          status={errors[phoneInputLabel] ? "error" : undefined}
                          {...register(phoneInputLabel, {
                            required: {
                              value: phoneField.isRequired,
                              message:
                                phoneField.errorMessage || t("common:required"),
                            },
                            validate: {
                              phoneNumberCharacters: (value) => {
                                return (
                                  !phoneField.isRequired ||
                                  customerValidation.phoneNumber.valid(
                                    phoneNumberUnMaskPipe(value)
                                  ) ||
                                  t("common:phone_min_length")
                                );
                              },
                              validateAreaCode: (value) =>
                                customerValidation.phoneNumber.validAreaCode(
                                  phoneNumberUnMaskPipe(value)
                                ) ||
                                t("common:form_error_invalid_phone_number"),
                            },
                          })}
                        />
                      </div>
                    );
                  }
                  case "SELECT": {
                    const dropdownField = field as DropdownFieldLayout;
                    const dropdownName = getDropdownName(dropdownField.label);
                    const selectedItem = selectedDropdownValues.find(
                      (item) => item.name === dropdownName
                    );
                    return (
                      <div
                        key={dropdownField.databaseId}
                        className={dropdownField.cssClass ?? ""}
                      >
                        <Controller
                          control={control}
                          rules={{
                            onChange: (e) =>
                              handleDropdownChange(
                                dropdownName,
                                e.target.value
                              ),
                            required: {
                              value:
                                !vinsolutionsId &&
                                dropdownName === "preferredLocation"
                                  ? true
                                  : dropdownField.isRequired,
                              message:
                                dropdownField.errorMessage ||
                                t("common:required"),
                            },
                          }}
                          name={dropdownName}
                          render={({ field: { onChange } }) => (
                            <Listbox
                              keyOption={(i) => i.id}
                              valueOption={(i) => i}
                              label={dropdownField.label}
                              displayOption={(i) => i?.name || ""}
                              onChange={onChange}
                              displayButton={(item) =>
                                item?.name ||
                                dropdownField.placeholder ||
                                t("common:dropdown_placeholder")
                              }
                              selectedItem={selectedItem?.value || undefined}
                              items={dropdownField.choices.map((choice) => ({
                                name: choice.text,
                                id: choice.value,
                              }))}
                              feedback={errors[dropdownName]?.message}
                              status={
                                errors[dropdownName] ? "error" : undefined
                              }
                              menuPosition={isPopup ? "Up" : "Down"}
                            />
                          )}
                        />
                      </div>
                    );
                  }
                  case "CHECKBOX": {
                    const checkboxField = field as CheckboxFieldLayout;
                    const checkboxNames = getCheckboxNames(checkboxField);
                    const hasError =
                      checkboxField.isRequired &&
                      isSubmitted &&
                      !checkboxNames.some((checkboxName) =>
                        Object.values(selectedCheckboxValues).some(
                          (checkbox) =>
                            !Object.prototype.hasOwnProperty.call(
                              checkbox,
                              checkboxName
                            )
                        )
                      );
                    return (
                      <div
                        key={checkboxField.databaseId}
                        className={checkboxField.cssClass ?? "flex flex-col"}
                      >
                        <p className="uppercase flex-initial text-gray-500 caption-1 flex gap-2">
                          {checkboxField.label}
                        </p>
                        {checkboxField.choices.map((choice, i) => {
                          return (
                            <Form.Checkbox
                              key={choice.text}
                              id={choice.text}
                              label={choice.text}
                              {...register(checkboxNames[i])}
                              checked={selectedCheckboxValues.some(
                                (checkboxValue) =>
                                  Object.prototype.hasOwnProperty.call(
                                    checkboxValue,
                                    checkboxNames[i]
                                  )
                              )}
                              feedback={errors[checkboxNames[i]]?.message}
                              status={
                                errors[checkboxNames[i]] ? "error" : undefined
                              }
                              onChange={(e) => {
                                handleCheckboxChange(
                                  checkboxNames[i],
                                  e.target.checked,
                                  checkboxField.label
                                );
                                checkboxNames.forEach((checkbox) =>
                                  checkbox !== checkboxNames[i]
                                    ? setValue(checkbox, false)
                                    : setValue(checkbox, true)
                                );
                              }}
                            />
                          );
                        })}
                        {hasError && (
                          <Feedback status="error">
                            {checkboxField.errorMessage || t("common:required")}
                          </Feedback>
                        )}
                      </div>
                    );
                  }
                  case "TEXTAREA": {
                    const textAreaField = field as TextAreaFieldLayout;
                    const textAreaLabel = sanitizeFieldName(
                      textAreaField.label
                    );
                    return (
                      <div
                        key={textAreaField.databaseId}
                        className={textAreaField.cssClass ?? ""}
                      >
                        <Form.TextArea
                          id={textAreaLabel}
                          label={textAreaLabel}
                          placeholder={textAreaField.placeholder}
                          maxLength={
                            !textAreaField.maxLength ||
                            textAreaField.maxLength === 0
                              ? 200
                              : textAreaField.maxLength
                          }
                          feedback={errors[textAreaLabel]?.message}
                          status={errors[textAreaLabel] ? "error" : undefined}
                          suppressHotjar
                          {...register(textAreaLabel, {
                            required: {
                              value: textAreaField.isRequired,
                              message:
                                textAreaField.errorMessage ||
                                t("common:required"),
                            },
                          })}
                        />
                      </div>
                    );
                  }
                  case "CONSENT": {
                    const consentField = field as ConsentFieldLayout;
                    return (
                      <div
                        key={consentField.databaseId}
                        className={consentField.cssClass ?? "col-span-2"}
                      >
                        <Form.Checkbox
                          id={consentField.checkboxLabel}
                          label={consentField.checkboxLabel}
                          feedback={errors.consent?.message}
                          status={errors.consent ? "error" : undefined}
                          {...register("consent", {
                            required: {
                              value: consentField.isRequired,
                              message:
                                consentField.errorMessage ||
                                t("common:required"),
                            },
                          })}
                        />
                      </div>
                    );
                  }
                  case "TEXT": {
                    const singleLineInputField =
                      field as SingleLineInputFieldLayout;
                    const singleLineInputLabel = sanitizeFieldName(
                      singleLineInputField.label
                    );
                    return (
                      <div
                        key={singleLineInputField.databaseId}
                        className={singleLineInputField.cssClass ?? ""}
                      >
                        <Form.Input
                          id={singleLineInputLabel}
                          label={singleLineInputLabel}
                          placeholder={singleLineInputField.placeholder}
                          maxLength={
                            !singleLineInputField.maxLength ||
                            singleLineInputField.maxLength === 0
                              ? 100
                              : singleLineInputField.maxLength
                          }
                          feedback={errors[singleLineInputLabel]?.message}
                          status={
                            errors[singleLineInputLabel] ? "error" : undefined
                          }
                          suppressHotjar
                          {...register(singleLineInputLabel, {
                            required: {
                              value: singleLineInputField.isRequired,
                              message:
                                singleLineInputField.errorMessage ||
                                t("common:required"),
                            },
                          })}
                        />
                      </div>
                    );
                  }
                  case "SECTION": {
                    const sectionBreakField = field as SectionBreakFieldLayout;
                    return (
                      <div
                        key={sectionBreakField.databaseId}
                        className={`col-span-2 ${sectionBreakField.cssClass}`}
                      >
                        <h5>{sectionBreakField.label}</h5>
                      </div>
                    );
                  }
                  default:
                    return null;
                }
              })}
              <div
                className={classNames(
                  `flex flex-col gap-5 md:flex-row md:col-span-2 items-center`,
                  {
                    "justify-center": isPopup,
                    "md:items-start": !isPopup,
                  }
                )}
              >
                <Button
                  isSubmit
                  buttonStyle="primary"
                  className="flex-shrink-0"
                  rightIcon={<ArrowRightIcon />}
                >
                  {submitButtonText || t("common:submit")}
                </Button>
              </div>
              <div
                className={classNames(
                  `flex flex-col justify-center md:justify-start gap-5 md:flex-row md:items-center md:col-span-2`,
                  {
                    "md:text-left": isPopup,
                  }
                )}
              >
                <Trans t={t} i18nKey="common:general_form_disclaimer">
                  <p className="italic text-xs">
                    <TextLink href="/privacy-policy">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a target="_blank" />
                    </TextLink>
                    <TextLink href="/terms-conditions">
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a target="_blank" />
                    </TextLink>
                  </p>
                </Trans>
              </div>
            </form>
          </div>
          {backgroundImageGroup &&
            backgroundImageGroup.image &&
            backgroundImageGroup.image.length > 0 && (
              <FormSelectorBackgroundImage
                backgroundImageGroup={backgroundImageGroup}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default GravityForm;
