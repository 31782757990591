import { ExtendedWarrantyRatesRequest } from "./types/ExtendedWarrantyRates";
import { ProductRateRequest } from "./types/AftermarketPurchase";
import { Purchase } from "./types/Purchase";
import { VehicleFinancePricingRequest } from "./types/VehicleFinancePricingBreakdown";
import { DealFinancePricingRequest } from "./types/DealFinancePricingBreakdown";

const purchaseQueryKeys = {
  all: ["purchases"] as const,
  products: () => [...purchaseQueryKeys.all, "products"] as const,
  activePurchase: () => [...purchaseQueryKeys.all, "active"] as const,
  activeAftermarketPurchase: (token?: string | null | undefined) =>
    [...purchaseQueryKeys.all, "active-aftermarket", token] as const,
  activeAftermarketDocuments: (purchase: Purchase | undefined) =>
    [
      ...purchaseQueryKeys.all,
      "active-aftermarket-documents",
      purchase?.id,
      purchase?.documentSummary.dealDocuments?.map((d) => d.id).join(","),
    ] as const,
  activeFinancingPurchase: () =>
    [...purchaseQueryKeys.all, "active-financing"] as const,
  activeFinancingSellerPurchase: () =>
    [...purchaseQueryKeys.all, "active-seller-financing"] as const,
  activeAfterMarketPurchaseProviderSecret: () =>
    [
      ...purchaseQueryKeys.activeAftermarketPurchase(),
      "transactionSecret",
    ] as const,
  activeAfterMarketPurchaseESignature: () =>
    [
      ...purchaseQueryKeys.activeAftermarketPurchase(),
      "documentsAreSigned",
    ] as const,
  productPricingPreview: (props: ProductRateRequest) =>
    [...purchaseQueryKeys.all, "productPricingPreview", props] as const,
  extendedWarrantyAllRates: () =>
    [...purchaseQueryKeys.all, "extendedWarrantyAllRates"] as const,
  extendedWarrantyDefaultRates: (
    props: ExtendedWarrantyRatesRequest,
    locale: string
  ) =>
    [
      ...purchaseQueryKeys.all,
      "extendedWarrantyDefaultRates",
      props,
      locale,
    ] as const,
  eligibleProvinces: (dealSource?: string) =>
    [...purchaseQueryKeys.all, "eligible-provinces", dealSource] as const,
  financePricingBreakdown: (query: VehicleFinancePricingRequest) =>
    [...purchaseQueryKeys.all, "financePricingBreakdown", query] as const,
  financePricingDefaults: () =>
    [...purchaseQueryKeys.all, "financePricingDefaults"] as const,
  deal: (id: string) => [...purchaseQueryKeys.all, id] as const,
  dealTodos: (id: string) => [...purchaseQueryKeys.deal(id), "todos"] as const,
  lenderPrograms: (dealId: string) =>
    [purchaseQueryKeys.deal(dealId), "lender-programs"] as const,
  submissionApproval: (submissionId: string) =>
    [
      ...purchaseQueryKeys.activeFinancingPurchase(),
      "submission-approval",
      submissionId,
    ] as const,
  payoutEmbedUrl: (dealId: string | null) =>
    [...purchaseQueryKeys.all, "generate-embed-url", dealId] as const,
  getDealLink: (token: string | null | undefined) =>
    [...purchaseQueryKeys.all, "get-validate-link", token] as const,
  dealFinancePricingBreakdown: (query: DealFinancePricingRequest) =>
    [...purchaseQueryKeys.all, "dealFinancePricingBreakdown", query] as const,
  purchase: (dealIds: string[]) =>
    [...purchaseQueryKeys.all, "purchase", ...dealIds] as const,
  afterMarketSampleContract: (
    locale: string,
    provincePostalAbbr: string | undefined,
    programId: string | undefined
  ) =>
    [
      ...purchaseQueryKeys.all,
      "afterMarketSampleContract",
      locale,
      provincePostalAbbr,
      programId,
    ] as const,
  promoCode: (promoCode: string) =>
    [...purchaseQueryKeys.all, "promoCode", promoCode] as const,
};

export default purchaseQueryKeys;
